import { useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.css'
// import { Swiper, SwiperSlide } from 'swiper/react';
import CancelIcon from '../../static/img/12.png'
import ConfirmIcon from '../../static/img/11.png'
// import { SketchPicker } from 'react-color';
// import FreeColorIcon from '../../static/img/19.png'
import { Range, ConfigProvider } from '@nutui/nutui-react';
 

const Index = (props) => {
    const {onPreview,onCancel,onConfirm} = props
    const [boxShow, setBoxShow] = useState(false)
    // const [cur, setCur] = useState({})
    const [free, setFree] = useState(false)


    useImperativeHandle(props.action, () => ({
        show: async (s) => {
            setBoxShow(s)
        }
    }));

    useEffect(()=>{
        if(!boxShow){
            // setCur({})
            setFree(false)
        }
    },[boxShow])



    const slideValue = (v) => {
        onPreview(v)
    }


    return (
        <>
            <div className={styles.bottomBox} style={{
                display: boxShow && !free ? "block":"none",
                height: 111
            }}>
                <div className={styles.bottomTitle} style={{borderBottom: "solid 1px #EFF0F1"}}>
                    <div style={{marginLeft: 12}} onClick={() => {
                        setBoxShow(false)
                        onCancel()
                    }}>
                        <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                    <div style={{width: "calc(100% - 96px)", textAlign: "center", fontSize: 13, color:"#222222",fontWeight: 400}}>
                        透明度
                    </div>
                    <div style={{marginRight: 12}} onClick={() => {
                        setBoxShow(false)
                        onConfirm()
                    }}>
                        <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                </div>
                <div className={styles.patternBox}>
                    <div style={{height: 35}}></div>
                    <div style={{padding: "0 18px"}}>
                        <ConfigProvider
                            theme={{
                                nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                nutuiRangeActiveColor:'#AAFF00',
                                nutuiRangeInactiveColor: '#DCDCDC',
                                nutuiRangeMargin: '10px',
                                nutuiRangeHeight: '4px',
                                nutuiRangeButtonWidth: '16px',
                                nutuiRangeButtonHeight: '16px'
                            }}
                        >
                            <Range
                                min={0}
                                max={100}
                                defaultValue={100}
                                style={{ color: 'red' }}
                                onChange={slideValue}
                                // value={values["0"]}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index