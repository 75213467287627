import React, { useImperativeHandle, useRef } from 'react';
import {Toast } from '@nutui/nutui-react';
import http from '../../lib/http'
import * as apis from '../../lib/api'
import md5 from 'md5'
import axios from 'axios'


function optimizeImage(file, maxSizeInBytes, callback) {
    // 检查文件大小是否超过指定的最大大小
    if (file.size <= maxSizeInBytes) {
        // 如果文件小于或等于最大大小，则不需要优化，直接调用回调函数
        callback(file);
        return;
    }

    // 创建一个用于加载图像的 FileReader 对象
    var reader = new FileReader();

    // 当 FileReader 完成加载时执行此回调函数
    reader.onload = function (e) {
        var img = new Image();
        img.onload = function () {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            // 计算缩放比例，将图像大小限制在最大大小以下
            var scaleFactor = Math.min(1, Math.sqrt(maxSizeInBytes / file.size));

            // 设置画布尺寸
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;

            // 在画布上绘制图像
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // 将画布上的图像转换为 Blob 对象
            canvas.toBlob(function (blob) {
                // 创建一个新的文件对象，以便将其传递给回调函数
                var optimizedFile = new File([blob], file.name, { type: file.type });

                // 调用回调函数，并传递优化后的文件对象
                callback(optimizedFile);
            }, file.type);
        };

        img.src = e.target.result;
    };

    // 读取文件数据
    reader.readAsDataURL(file);
}


const Index = (props) => {
    const {onConfirm} = props
    const ref = useRef()

    useImperativeHandle(props.action, () => ({
        openFile: async () => {
            ref.current.click()
        }
    }));

    const fileChoice = async (e) => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0]
            var maxSize = 2 * 1024 * 1024; // 3MB
            Toast.show({
                content: "上传中",
                icon: 'loading',
                duration: 0
            });
            optimizeImage(file, maxSize, async (optimizedFile) => {
                let ss = file.name.split(".")
                if (ss.length === 0) {
                    Toast.fail("文件格式不正确")
                    return
                }
                //上传效果图
                const uuid = await http.get(apis.UUID)
                const filename = `${md5(uuid)}.${ss[ss.length - 1]}`;

                const formData = new FormData();
                formData.append('file', file, filename); // 第一个参数是字段名，第二个参数是 Blob，第三个参数是文件名
                let response = await axios.post(`${process.env.REACT_APP_API_HOST}${apis.UPLOAD}`, formData, {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                onConfirm(response?.data?.data)
                Toast.clear()
            });
            e.target.value = ''
        }


    }

    return (
        <div style={{display: "none"}}>
            <input accept="image/*" type='file' onChange={fileChoice} ref={ref} />
        </div>

    )

}

export default Index