import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Tiezhi from '../../static/img/2.png'
import Zhaopian from '../../static/img/3.png'
import Wenzi from '../../static/img/1.png'
import Koutu from '../../static/img/8.png'
// import Lvjing from '../../static/img/6.png'
// import Tiaojie from '../../static/img/9.png'
import Fuzhi from '../../static/img/4.png'
import Jingxiang from '../../static/img/7.png'
import Xiayi from '../../static/img/5.png'
import Shanchu from '../../static/img/10.png'
// import Yanse from '../../static/img/13.png'
import styles from './index.module.css'

const Index = (props) => {
    const { onMenu, curActivityObject } = props
    const swiperClient = useRef()
    const menus = [
        {
            "id": 1,
            "txt":"贴纸",
            "icon": Tiezhi
        },
        {
            "id": 2,
            "txt":"照片",
            "icon": Zhaopian
        },
        // {
        //     "id": 3,
        //     "txt":"颜色",
        //     "icon": Yanse
        // },
        {
            "id": 4,
            "txt":"文字",
            "icon": Wenzi
        },
        {
            "id": 11,
            "txt":"抠图",
            "icon": Koutu
        },
        // {
        //     "id":5,
        //     "txt":"滤镜",
        //     "icon": Lvjing
        // },
        // {
        //     "id": 6,
        //     "txt":"调节",
        //     "icon": Tiaojie
        // },
        {
            "id": 7,
            "txt":"复制",
            "icon": Fuzhi
        },
        {
            "id": 12,
            "txt":"透明度",
            "icon": 'https://file.letodiy.top/22.png?1'
        },
        {
            "id": 8,
            "txt":"镜像",
            "icon": Jingxiang
        },
        {
            "id": 9,
            "txt":"下移",
            "icon": Xiayi
        },
        {
            "id": 10,
            "txt":"删除",
            "icon": Shanchu
        }
    ]

    const choiceMenu = (v,k) => {
        if(isDisabled(v)){
            return
        }
        onMenu(v,k)
    }

    const isDisabled = (v) => {
        //curActivityObject?.isType("image")
        if(v.id === 11){
            if(curActivityObject?.isType("image")){
                return false
            }
            return true
        }
        if(!curActivityObject && (v.id === 5 || v.id === 6 || v.id === 8 || v.id === 10 || v.id === 7 || v.id === 9 || v.id === 12)){
            return true
        }
        if(curActivityObject && curActivityObject?.isType("text") && (v.id === 5 || v.id === 6 || v.id === 11)){
            return true
        }
        return false
    }

    return (
        <div className={styles.bottom}>
            <Swiper
                onSwiper={s => swiperClient.current = s}
                slidesPerView={"auto"}
                style={{
                    width: "96%",
                    height: "100%"
                }}
            >
                {
                    menus.map((v, k) => (
                        <SwiperSlide onClick={() => choiceMenu(v,k)} style={{
                            width: '67px',
                            // paddingRight: k === menus.length - 1 ? 0:43,
                            opacity: isDisabled(v)  ? 0.5 : 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }} key={k}>
                            <div style={{textAlign: "center",position: "relative"}}>
                                {
                                    v.id === 1 && <img src='https://file.letodiy.top/%E8%B7%AF%E5%BE%84%209%402x.png' style={{
                                        width: 12,
                                        height:16,
                                        position: "absolute",
                                        top: -7,
                                        right: -5,
                                    }} alt='' />
                                }
                                <div>
                                    <img style={{width: 20, height: 20}} src={v.icon} alt='' />
                                </div>
                                <div className={styles.bottomTxt}>{v.txt}</div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default Index