import { useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.css'
import CancelIcon from '../../static/img/12.png'
import ConfirmIcon from '../../static/img/11.png'
import Ld from '../../static/img/14.png'
import Sx from '../../static/img/15.png'
import Bhd from '../../static/img/16.png'
import Dbd from '../../static/img/17.png'
import { Range, ConfigProvider } from '@nutui/nutui-react';
import { cloneDeep } from 'lodash';
import {processor} from '../../lib/tool'
 

const Index = (props) => {
    const {onPreview,onCancel,onConfirm} = props
    const [boxShow, setBoxShow] = useState(false)
    const [cur, setCur] = useState(0)
    const [values, setValues] = useState({"0":0,"1":0,"2":0,"3":0})
    useImperativeHandle(props.action, () => ({
        show: async (s) => {
            setBoxShow(s)
        }
    }));

    useEffect(()=>{
        if(!boxShow){
            setValues({"0":0,"1":0,"2":0,"3":0})
        }
    },[boxShow])


    const slideValue = (v) => {
        let cpValues = cloneDeep(values)
        cpValues[cur] = v
        setValues(cpValues)
        processor.process(() => {
            onPreview(cur,v)
        })
    }



    return (
        <>
            <div className={styles.bottomBox} style={{
                height: 188,
                display: boxShow ? "block":"none"
            }}>
                <div className={styles.bottomTitle} style={{borderBottom: "solid 1px #EFF0F1"}}>
                    <div style={{marginLeft: 12}} onClick={() => {
                        setBoxShow(false)
                        onCancel()
                    }}>
                        <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                    <div style={{width: "calc(100% - 96px)", textAlign: "center", fontSize: 13, color:"#222222",fontWeight: 400}}>
                        调整
                    </div>
                    <div style={{marginRight: 12}} onClick={() => {
                        setBoxShow(false)
                        onConfirm()
                    }}>
                        <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                </div>
                <div className={styles.patternBox}>
                    <div style={{height: 20}}></div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-around"
                    }}>
                        <div className={styles.slideItem} onClick={() => setCur(0)}>
                            <img src={Ld} alt='' style={{opacity: cur !== 0 ? 0.5:1}} />
                            <div>亮度</div>
                        </div>
                        <div className={styles.slideItem} onClick={() => setCur(1)}>
                            <img src={Dbd} alt='' style={{opacity: cur !== 1 ? 0.5:1}} />
                            <div>对比度</div>
                        </div>
                        <div className={styles.slideItem} onClick={() => setCur(2)}>
                            <img src={Bhd} alt='' style={{opacity: cur !== 2 ? 0.5:1}} />
                            <div>饱和度</div>
                        </div>
                        <div className={styles.slideItem} onClick={() => setCur(3)}>
                            <img src={Sx} alt='' style={{opacity: cur !== 3 ? 0.5:1}} />
                            <div>色相</div>
                        </div>
                    </div>
                    <div style={{height: 24}}></div>
                    <div style={{padding: "0 18px",display: cur === 0 ? "block":"none"}}>
                        <ConfigProvider
                            theme={{
                                nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                nutuiRangeActiveColor:'#AAFF00',
                                nutuiRangeInactiveColor: '#DCDCDC',
                                nutuiRangeMargin: '10px',
                                nutuiRangeHeight: '4px',
                                nutuiRangeButtonWidth: '16px',
                                nutuiRangeButtonHeight: '16px'
                            }}
                            >
                            <Range
                                min={-50}
                                max={50}
                                style={{ color: 'red' }}
                                onChange={slideValue}
                                value={values["0"]}
                            />
                        </ConfigProvider>
                    </div>
                    <div style={{padding: "0 18px",display: cur === 1 ? "block":"none"}}>
                        <ConfigProvider
                            theme={{
                                nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                nutuiRangeActiveColor:'#AAFF00',
                                nutuiRangeInactiveColor: '#DCDCDC',
                                nutuiRangeMargin: '10px',
                                nutuiRangeHeight: '4px',
                                nutuiRangeButtonWidth: '16px',
                                nutuiRangeButtonHeight: '16px'
                            }}
                            >
                            <Range
                                min={-50}
                                max={50}
                                style={{ color: 'red' }}
                                onChange={slideValue}
                                value={values["1"]}
                            />
                        </ConfigProvider>
                    </div>
                    <div style={{padding: "0 18px",display: cur === 2 ? "block":"none"}}>
                        <ConfigProvider
                            theme={{
                                nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                nutuiRangeActiveColor:'#AAFF00',
                                nutuiRangeInactiveColor: '#DCDCDC',
                                nutuiRangeMargin: '10px',
                                nutuiRangeHeight: '4px',
                                nutuiRangeButtonWidth: '16px',
                                nutuiRangeButtonHeight: '16px'
                            }}
                            >
                            <Range
                                min={-50}
                                max={50}
                                style={{ color: 'red' }}
                                onChange={slideValue}
                                value={values["2"]}
                            />
                        </ConfigProvider>
                    </div>
                    <div style={{padding: "0 18px",display: cur === 3 ? "block":"none"}}>
                        <ConfigProvider
                            theme={{
                                nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                nutuiRangeActiveColor:'#AAFF00',
                                nutuiRangeInactiveColor: '#DCDCDC',
                                nutuiRangeMargin: '10px',
                                nutuiRangeHeight: '4px',
                                nutuiRangeButtonWidth: '16px',
                                nutuiRangeButtonHeight: '16px'
                            }}
                            >
                            <Range
                                min={-50}
                                max={50}
                                style={{ color: 'red' }}
                                onChange={slideValue}
                                value={values["3"]}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index