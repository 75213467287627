import { useImperativeHandle, useRef, useState } from "react"
import CancelIcon from '../../static/img/12.png'
import PosterIcon1 from '../../static/poster/Frame 198.jpg'
import PosterIcon2 from '../../static/poster/Frame 199.jpg'
import PosterIcon3 from '../../static/poster/Frame 200.jpg'
import PosterIcon4 from '../../static/poster/Frame 201.jpg'
import PosterIcon5 from '../../static/poster/Frame 202.jpg'
import PosterIcon6 from '../../static/poster/Frame 204.jpg'
import PosterIcon7 from '../../static/poster/Frame 205.jpg'
import PosterIcon8 from '../../static/poster/Frame 206.jpg'
import PosterIcon9 from '../../static/poster/Frame 207.jpg'
import PosterIcon10 from '../../static/poster/Frame 208.jpg'
import PosterIcon11 from '../../static/poster/Frame 209.jpg'

const { html2canvas } = window

const Index = (props) => {
    const [cover, setCover] = useState('')
    const [preCover, setPreCover] = useState('')
    const [posterBg, setPosterBg] = useState()
    const [show, setShow] = useState(false)
    const posterBgs = useRef([
        PosterIcon1,
        PosterIcon2,
        PosterIcon3,
        PosterIcon4,
        PosterIcon5,
        PosterIcon6,
        PosterIcon7,
        PosterIcon8,
        PosterIcon9,
        PosterIcon10,
        PosterIcon11
    ])

    useImperativeHandle(props.action, () => ({
        make: (coverBase64,s) => {
            return new Promise((a,b) => {
                setShow(s)
                setPosterBg(posterBgs.current[Math.floor(Math.random()*posterBgs.current.length)])
                setCover(coverBase64)
                setTimeout(()=>{
                    html2canvas(document.getElementById("poster")).then(function(canvas) {
                        if(!s){
                            setCover('')
                        }
                        setPreCover(canvas.toDataURL())
                        a(canvas.toDataURL())
                    });
                },500)
            })
        }
    }));

    return (
        <div style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
            background: "rgba(0,0,0,0.7)",
            zIndex: 11,
            display: cover === '' ? "none":"block",
            opacity: show ? 1:0
        }}>
            <div style={{
                position: "fixed",
                margin: "auto",
                zIndex: 11,
                width: "100%",
                background: "#FFF",
                height: "90%",
                bottom: 0,
                borderRadius: "12px 12px 0 0"
            }}>
                <div style={{height: 35,display: "flex",justifyContent: "right",marginRight: 7,alignItems: "center"}}>
                    <img onClick={() => {
                        setCover('')
                    }} style={{width: 25,height: 27,flexShrink: 0}} src={CancelIcon} alt="" />
                </div>
                <div style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <div id="poster" style={{
                        width: 300,
                        height: 473,
                        position: "relative",
                        margin: "0 auto",
                        boxShadow: "0px 11px 39px rgba(0,0,0,0.3)"
                    }}>
                        <div style={{display: preCover !== '' ? "block":"none"}}>
                            <img style={{width: "100%"}} src={preCover} alt="" />
                        </div>
                        <div style={{display: preCover !== '' ? "none":"block"}}>
                            <img style={{
                                width: "100%",
                                position: "absolute",
                                left: 0,
                                top: 0
                            }} src={posterBg} alt="" />
                            <img style={{
                                width: "47%",
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: -75,
                                maxWidth: 200,
                                margin: "auto",
                                bottom: 0
                            }} src={cover} alt="" />
                        </div>
                    </div>
                    <div style={{height: 35}}></div>
                    <div style={{
                        fontSize: 14,
                        textShadow: "0px 17px 14px rgba(0,0,0,0.055), 0px 83px 85px rgba(0,0,0,0.11)",
                        textAlign: "center"
                    }}>长按保存后发给好友或者分享到朋友圈</div>
                </div>
            </div>
        </div>
    )
}

export default Index