export const PATTERN_LIST = `/c/api/pattern/list`
export const PATTERN_CAT_LIST = `/c/open/pattern/cats`
export const GOODS_DETAIL = `/c/api/goods/detail`
export const GOODS_TEMPLATE_CREATE = `/c/api/template/save`
export const GOODS_TEMPLATE_DETAIL = `/c/api/template/detail`
export const ORDER_PRE_CREATE = `/c/api/order/pre/create`
export const IMAGE_SPLIT = `/c/api/ai/image/split`


export const UUID = `/uuid`
export const UPLOAD = `/c/api/tool/upload`



