import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './index.module.css'
import { Toast } from '@nutui/nutui-react';
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Swiper, SwiperSlide } from 'swiper/react';
import CancelIcon from '../../static/img/12.png'
import ConfirmIcon from '../../static/img/11.png'
import { cloneDeep } from 'lodash';

const Index = (props) => {
    const {onPreview,onCancel,onConfirm} = props
    const [boxShow, setBoxShow] = useState(false)
    const [catList, setCatList] = useState([])
    const [curPattern, setCurPattern] = useState({})
    const [patternList, setPatternList] = useState({})
    const [curCat, setCurCat] = useState(0)
    const [search, setSearch] = useState({page: 1, page_size: 500, cat_id: 0})
    const swiperClient = useRef()

    useImperativeHandle(props.action, () => ({
        show: async (s) => {
            Toast.show({
                content: "加载中",
                icon: 'loading',
                duration: 0
            });
            try {
                let cats = await http.post(apis.PATTERN_CAT_LIST)
                setCatList(cats)
                await choiceCat(cats[0])
            } catch (error) {
                
            }
            setBoxShow(s)
            Toast.clear()
        }
    }));

    useEffect(()=>{
        if(!boxShow){
            setCurPattern({})
        }
    },[boxShow])

    const choiceCat = async (v) => {
        Toast.show({
            content: "加载中",
            icon: 'loading',
            duration: 0
        });
        setCurCat(v)
        try {
            let cpSearch = cloneDeep(search)
            cpSearch.cat_id = v.id
            setSearch(cpSearch)
            await loadPatternList(cpSearch)
        } catch (error) {
            
        }
        Toast.clear()
    }

    const choicePattern = (v) => {
        setCurPattern(v)
        onPreview(v)
    }

    const loadPatternList = async (s) => {
        try {
            let ret = await http.post(apis.PATTERN_LIST,s)
            ret.list = ret.list || []
            setPatternList(ret)
        } catch (error) {
            
        }
    }


    return (
        <div className={styles.bottomBox} style={{
            display: boxShow ? "block":"none"
        }}>
            <div className={styles.bottomTitle}>
                <div style={{marginLeft: 12}} onClick={() => {
                    setBoxShow(false)
                    onCancel()
                }}>
                    <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                </div>
                <div style={{width: "calc(100% - 96px)", display: "flex"}}>
                    <Swiper
                        onSwiper={s => swiperClient.current = s}
                        slidesPerView={"auto"}
                    >
                        <SwiperSlide style={{
                            width: 'auto',
                            paddingRight: 29
                        }}></SwiperSlide>
                        {
                            catList.map((v, k) => (
                                <SwiperSlide onClick={() => choiceCat(v)} style={{
                                    width: 'auto',
                                    paddingRight: k === catList.length - 1 ? 0:33
                                }} key={k}>
                                    <div style={{color: v.id === curCat.id && "#141414", borderColor: v.id === curCat.id && "#141414"}} className={styles.patternCatTxt}>{v.txt}</div>
                                </SwiperSlide>
                            ))
                        }
                        <SwiperSlide style={{
                            width: 'auto',
                            paddingRight: 29
                        }}></SwiperSlide>
                    </Swiper>
                </div>
                <div style={{marginRight: 12}} onClick={() => {
                    setBoxShow(false)
                    onConfirm()
                }}>
                    <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                </div>
            </div>
            <div className={styles.patternBox}>
                <div style={{height: 236, overflow: "auto", marginLeft: "2vw"}}>
                    <div style={{height: 20}}></div>
                    {
                        patternList?.list?.map(v => (
                            <div onClick={() => choicePattern(v)} key={v.id} style={{float: "left",marginLeft: "2vw", marginBottom: "2vw",borderRadius: "4px 4px 4px 4px",border: curPattern.id === v.id ? "3px solid #AAFF00":"3px solid rgba(0,0,0,0.1)",overflow: "hidden"}}>
                                <img src={process.env.REACT_APP_OSS_URL + '/' + v.small_cover} style={{width: "20vw", height: "20vw",display:'block'}} alt='' />
                            </div>
                        ))
                    }
                    <div style={{clear: "both",color: "#ccc",fontSize: 12,textAlign: "center",paddingBottom: 12}}>没有更多了~</div>
                </div>
            </div>
        </div>
    )
}

export default Index