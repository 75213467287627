import styles from './index.module.css'
import Bottom from './bottom'
import Pattern from './pattern'
import Lvjing from './lvjing'
import Photo from './photo'
import BgColor from './bg_color'
import Slide from './slide'
import Txt from './txt'
import Koutu from './koutu'
import Opacity from './opacity'
import {rgbaStringToObject} from '../../lib/tool'
import fabricClient from '../../lib/fabric'
import { useEffect, useRef,useState } from 'react'
import { Toast, Dialog } from '@nutui/nutui-react';
import patternClose from '../../static/img/pattern_close.png'
import patternScale from '../../static/img/pattern_scale.png'
import patternRote from '../../static/img/pattern_rote.png'
import textEdit from '../../static/img/txt_edit.png'
import SaveIcon from '../../static/img/107.png'
import ShareIcon from '../../static/img/108.png'
import OrderIcon from '../../static/img/106.png'
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { useLocation, useParams } from 'react-router-dom'
import md5 from 'md5'
import axios from 'axios'
import loadFont from '../../lib/font'
import Poster from './poster'
import { cloneDeep } from 'lodash'


const Index = () => {
    const dfabric = useRef()
    const canvas = useRef()
    const patternAction = useRef()
    const koutuAction = useRef()
    const lvjingAction = useRef()
    const photoAction = useRef()
    const slideAction = useRef()
    const bgAction = useRef()
    const txtAction = useRef()
    const opacityAction = useRef()
    const posterRef = useRef()
    const params = useParams()
    const l = useLocation()
    const previewPatternObject = useRef()
    const [controPosition, setControPosition] = useState()
    const [config, setConfig] = useState({})
    const [canvasEmpty, setCanvasEmpty] = useState(true)
    const [curActivityObject, setCurActivityObject] = useState()
    const [menuOpen, setMenuOpen] = useState(false)
    const [goodsInfo, setGoodsInfo] = useState({})

    const [txtValue, setTxtValue] = useState({
        color: '#000000',
        fontFamily: 'Times New Roman',
        fontSize: 60,
        strokeSize: 0,
        stroke: "#000",
        shadow: {
            a: 0,
            blur: 0,
            angle: 0,
            offsetX: 0,
            offsetY: 0
        },
        fontBgColor: {r:0,g:0,b:0,a:0},
        cuxieAttr: {'1': false,'2': false,'3': false},
        alignType: 0,
        scale: 1,
        charSpacing: 0,
        lineHeight:1
    })

    const txtEditMode = useRef(false)

    
    useEffect(()=>{
        const isTip = localStorage.getItem("isTip")
        if(!isTip){
            Dialog.alert({
                title: '提示',
                content: `1.本模板仅供设计参考，具体以实物为准 \n 2.为确保您DIY的产品效果最佳，请上传清晰、高分辨率的图片。`,
                hideCancelButton: true,
                confirmText: '确认'
            });
            localStorage.setItem("isTip","1")
        }
        

        localStorage.setItem("token",params.token)
        Toast.show({
            content: "加载中",
            icon: 'loading',
            duration: 0
        });
        let sp = new URLSearchParams(l.search)

        http.get(`${apis.GOODS_DETAIL}?hash_id=${params.id}`).then(async d => {
            d.model_config = JSON.parse(d.model_config)
            let conf = d.model_config
            setGoodsInfo(d)

            let jData = {objects: []}
            if(!isNaN(parseInt(sp.get("tpl_id")))){
                let templateInfo = await http.get(`${apis.GOODS_TEMPLATE_DETAIL}?id=${sp.get("tpl_id")}`) 
                jData = JSON.parse(templateInfo.j_data)
                console.log(jData)
            }
            
            // jData
            conf.bgUrl = process.env.REACT_APP_OSS_URL + "/" +d.bg_img
            setConfig(conf)
            dfabric.current = new fabricClient()
            canvas.current = dfabric.current.loadCanvas("canvas",conf.canvasWidth,conf.canvasHeight)
            
            canvas.current.onCanvas((e, controPosition, event) => {
                if (event === "after:render") {
                    // console.log(canvas.current.getFabricCanvas().backgroundColor)
                    setCanvasEmpty(pre => {
                        return canvas.current.getFabricCanvas().isEmpty()
                    })
                    const acObj = canvas.current.getFabricCanvas().getActiveObject()
                    if (acObj) {
                        if(!acObj.isType("text")){
                            txtAction.current.show(0)
                        }
                        setCurActivityObject(acObj)
                    } else {
                        setCurActivityObject(null)
                        txtAction.current.show(0)
                    }
                    setControPosition(controPosition)
                }
            })
            // console.log(jData)
            // jData.overlayImage.src = conf.bgUrl
            jData.background = conf.color || 'rgba(0,0,0,0)'

            const fontList = await axios.get("https://leto-file.oss-cn-beijing.aliyuncs.com/font/font.json?"+new Date().getTime())


            for(let i=0;i<jData.objects.length;i++){
                let item = jData.objects[i]
                if(item.type.indexOf("text") !== -1){
                    try {
                        const id = item.fontFamily.split("_")[1] 
                        
                        await loadFont(item.fontFamily, fontList.data.find(j => j.id === parseInt(id)).font)
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
            
            await dfabric.current.loadFromJSON(canvas.current.getFabricCanvas(),jData)
            
            canvas.current.setBackgroundImage(conf.bgUrl)

            
            let img = sp.get("img")
            if(img && img !== "undefined"){
                img = decodeURIComponent(img)
                await canvas.current.addImageById(img,conf.canvasWidth/2)
            }

            Toast.clear()
        })
        // eslint-disable-next-line
    },[])

    const delObject = () => {
        if(curActivityObject){
            canvas.current.removeObject(curActivityObject)
        }
    }

    const onMenu = async (v) => {
        //缓存当前的数据
        dfabric.current.cacheAllData()
        if(v.id === 3){
            setMenuOpen(true)
            bgAction.current.show(true)
        }
        if(v.id === 12){
            setMenuOpen(true)
            opacityAction.current.show(true)
        }
        if(v.id === 1){
            setMenuOpen(true)
            patternAction.current.show(true)
        }
        if(v.id === 4){
            txtEditMode.current = false
            setMenuOpen(true)
            txtAction.current.show(1)
        }
        if(v.id === 5){
            if(!curActivityObject){
                return
            }
            setMenuOpen(true)
            lvjingAction.current.show(true)
        }
        if(v.id === 6){
            if(!curActivityObject){
                return
            }
            setMenuOpen(true)
            slideAction.current.show(true)
        }
        if(v.id === 2){
            photoAction.current.openFile()
        }
        if(v.id === 7){
            canvas.current.copyObject()
        }
        if(v.id === 8){
            if(curActivityObject){
                canvas.current.jingX(curActivityObject)
            }
        }
        if(v.id === 9){
            if(curActivityObject){
                canvas.current.getFabricCanvas().sendBackwards(curActivityObject)
                canvas.current.getFabricCanvas().renderAll()
            }
        }
        if(v.id === 10){
            delObject()
        }
        if(v.id === 11){
            setMenuOpen(true)
            koutuAction.current.show(true)
        }
    }

    const onPatternPreview = async (v) => {
        Toast.show({
            content: "加载中",
            icon: 'loading',
            duration: 0
        });
        if (previewPatternObject.current) {
            canvas.current.removeObject(previewPatternObject.current)
            previewPatternObject.current = null
        }
        previewPatternObject.current = await canvas.current.addImageById(process.env.REACT_APP_OSS_URL+"/"+v.img_url,config.canvasWidth/2)
        Toast.clear()
    }

    const onPatternCancel = () => {
        setMenuOpen(false)
        dfabric.current.resetDataFromCache()
    }

    const onPatternConfirm = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
        previewPatternObject.current = null
    }

    const onLvjingCancel = () => {
        setMenuOpen(false)
        dfabric.current.resetDataFromCache()
    }

    const onLvjingConfirm = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
    }

    const onBgColorCancel = () => {
        setMenuOpen(false)
        dfabric.current.resetDataFromCache()
    }

    const onOpacityCancel = () => {
        setMenuOpen(false)
        dfabric.current.resetDataFromCache()
    }

    const onBgColorConfirm = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
    }

    const onOpacityConfirm = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
    }

    const onBgColorPreview = (v) => {
        canvas.current.setBackgroundColor(v)
    }

    const onSlideCancel = () => {
        setMenuOpen(false)
        dfabric.current.resetDataFromCache()
    }

    const onSlideConfirm = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
    }

    const onKoutuCancel = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
        dfabric.current.resetDataFromCache()
    }

    const onKoutuConfirm = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
    }

    const onKoutuPreview = async (b) => {
        Toast.show({
            content: "加载中",
            icon: 'loading',
            duration: 0
        });
        try {
            let path = await http.post(apis.IMAGE_SPLIT, {
                url: curActivityObject._originalElement.src + "?x-oss-process=image/resize,l_1999",
                tp: b === '1' ? 0:1
            })
            delObject()
            if (previewPatternObject.current) {
                canvas.current.removeObject(previewPatternObject.current)
                previewPatternObject.current = null
            }
            previewPatternObject.current = cloneDeep(await canvas.current.addImageById(path,config.canvasWidth/2))
            // console.log(path)
        } catch (error) {
            
        }
        Toast.clear() 
    }

    const onTxtCancel = () => {
        setMenuOpen(false)
        dfabric.current.resetDataFromCache()
    }

    const onTxtConfirm = () => {
        setMenuOpen(false)
        canvas.current.discardActiveObject()
    }

    const onSlidePreview = (cur,v) => {
        if(!curActivityObject){
            return
        }
        if (cur === 0){
            canvas.current.slideLd(curActivityObject,v)
        }
        if (cur === 1){
            canvas.current.slideDbd(curActivityObject,v)
        }
        if (cur === 2){
            canvas.current.slideBh(curActivityObject,v)
        }
        if (cur === 3){
            canvas.current.slideSx(curActivityObject,v)
        }
    }

    const onPhotoConfirm = async (data) => {
        setMenuOpen(false)
        Toast.show({
            content: "加载中",
            icon: 'loading',
            duration: 0
        });
        await canvas.current.addImageById(data.url,config.canvasWidth/2)
        Toast.clear()
    }

    const onLvjingPreview = (v) => {
        if(curActivityObject){
            canvas.current.slideDiyLvJing(curActivityObject,v.colors)
        }
    }

    const onTextAddText = (txt) => {
        if(txtEditMode.current && curActivityObject){
            curActivityObject.text = txt
            canvas.current.getFabricCanvas().renderAll()
        }else{
            canvas.current.addText(txt)
        }
    }

    const editTxt = () => {
        if(!curActivityObject){
            return
        }
        txtEditMode.current = true
        txtAction.current.setInputValue(curActivityObject.text)

        setMenuOpen(true)
        dfabric.current.cacheAllData();
        txtAction.current.show(1)
        // console.log(curActivityObject)
        
        try {
            var rgbColor = rgbaStringToObject(curActivityObject.shadow?.color)
        } catch (error) {
            
        }
        
        setTxtValue({
            color: curActivityObject.fill,
            fontFamily: curActivityObject.fontFamily ? curActivityObject.fontFamily:'Times New Roman',
            fontSize: curActivityObject.fontSize,
            strokeSize: curActivityObject.strokeWidth,
            stroke: curActivityObject.stroke,
            shadow: {
                a: rgbColor?.a,
                blur: curActivityObject.shadow?.blur,
                angle: curActivityObject.shadow?.angle,
                offsetX: curActivityObject.shadow?.offsetX,
                offsetY: curActivityObject.shadow?.offsetY
            },
            fontBgColor: rgbaStringToObject(curActivityObject.textBackgroundColor),
            cuxieAttr: {'1': curActivityObject.fontStyle !== "normal",'2': curActivityObject.fontWeight !== "normal",'3': false},
            alignType: 0,
            scale: curActivityObject.scaleX,
            charSpacing: curActivityObject.charSpacing,
            lineHeight:curActivityObject.lineHeight
        })
    }

    const onTxtPreview = v => {
        if(!curActivityObject){
            return
        }
        canvas.current.setTxtColor(curActivityObject, v.color)
        let c = canvas.current.getFabricCanvas()
        canvas.current.setTxtSize(curActivityObject,v.fontSize)
        canvas.current.setTxtStrokeSize(curActivityObject,v.strokeSize,v.stroke)
        canvas.current.setShadow(curActivityObject,v.shadow)
        canvas.current.setTxtFontBgColor(curActivityObject, `rgba(${v.fontBgColor.r},${v.fontBgColor.g},${v.fontBgColor.b},${v.fontBgColor.a})`)

        canvas.current.setTxtFontI(curActivityObject,v.cuxieAttr['1'] ? 'italic':'normal')
        canvas.current.setTxtFontB(curActivityObject,v.cuxieAttr['2'] ? 'bold':'normal')
        canvas.current.setTxtFontU(curActivityObject,v.cuxieAttr['3'] ? 'underline':'normal')

        let changeObj = {
            lineHeight: v.lineHeight,
            // scaleX: v.scale, 
            // scaleY: v.scale,
            charSpacing: v.charSpacing,
            fontFamily: v.fontFamily,
            fontFamilyUrl: v.fontFamilyUrl
        }

        if(!changeObj.fontFamily){
            delete changeObj.fontFamily
        }
        
        if(v.alignType === 20){
            changeObj.left = 0
        }
        if(v.alignType === 21){
            // 计算Canvas的宽度
            let canvasWidth = config.canvasWidth;
            // 计算对象的宽度
            let objWidth = curActivityObject.width*curActivityObject.scaleX;
            // 计算对象应该水平居中的左侧位置
            let leftPosition = (canvasWidth - objWidth) / 2;
            changeObj.left = leftPosition
        }
        if(v.alignType === 22){
            // 计算Canvas的宽度
            let canvasWidth = config.canvasWidth;
            // 计算对象的宽度
            let objWidth = curActivityObject.width*curActivityObject.scaleX;
            // 计算对象应该水平居中的左侧位置
            let leftPosition = canvasWidth - objWidth;
            // 设置对象的左侧位置以实现水平居中
            changeObj.left = leftPosition
        }
        if(v.alignType === 23){
            changeObj.top = 0
        }
        if(v.alignType === 24){
            // 计算Canvas的宽度
            let canvasHeight = config.canvasHeight;
            // 计算对象的宽度
            let objHeight = curActivityObject.height*curActivityObject.scaleY;
            // 计算对象应该水平居中的左侧位置
            let topPosition = (canvasHeight - objHeight) / 2;
            changeObj.top = topPosition
        }
        if(v.alignType === 25){
            // 计算Canvas的宽度
            let canvasHeight = config.canvasHeight;
            // 计算对象的宽度
            let objHeight = curActivityObject.height*curActivityObject.scaleY;
            // 计算对象应该水平居中的左侧位置
            let topPosition = (canvasHeight - objHeight);
            changeObj.top = topPosition
        }
        
        curActivityObject.set(changeObj)
        c.renderAll()
        setTxtValue(v)
    }

    const makeTemplateData = async () => {
        try {
            let blobs = await canvas.current.toBlob(config.canvasWidth, config.canvasHeight)
            let coverBlob = blobs[0]
            let jData = blobs[2]
            let material = blobs[1]

            //上传效果图
            const uuid = await http.get(apis.UUID)
            const filename = `${md5(uuid)}.png`;
            const formData = new FormData();
            formData.append('file',coverBlob, filename); // 第一个参数是字段名，第二个参数是 Blob，第三个参数是文件名
            let response = await axios.post(`${process.env.REACT_APP_API_HOST}${apis.UPLOAD}`, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            })

            //上传生产图
            const uuid2 = await http.get(apis.UUID)
            const filename2 = `${md5(uuid2)}.png`;
            const formData2 = new FormData();
            formData2.append('file',material, filename2); // 第一个参数是字段名，第二个参数是 Blob，第三个参数是文件名
            let response2 = await axios.post(`${process.env.REACT_APP_API_HOST}${apis.UPLOAD}`, formData2, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Content-Type': 'multipart/form-data'
                }
            })

            let tplInfo = await http.post(apis.GOODS_TEMPLATE_CREATE,{
                cover: response?.data?.data?.path,
                material: response2?.data?.data?.path,
                j_data: jData,
                goods_hash: params.id
            })
            return {tpl_id: tplInfo.id, cover: response?.data?.data?.path, material: response2?.data?.data?.path}
            // window.location.reload()
        } catch (error) {
            
        }
    }

    //保存到模板
    const saveToTemplate = async () => {
        Toast.show({
            content: "加载中",
            icon: 'loading',
            duration: 0
        });
        try {
            await makeTemplateData()
            window.wx.miniProgram.navigateTo({ url: `/pages/design_my/design_my?cat_id=0`})
            // window.location.reload()
        } catch (error) {
            
        }
        Toast.clear()
    }

    const doOrder = async () => {
        let preOrderNo
        Toast.show({
            content: "图片合成中，请耐心等待",
            icon: 'loading',
            duration: 0
        });
        try {
            let cover = canvas.current.getFabricCanvas().toDataURL()
            let poster = await posterRef.current.make(cover, false)
            let ret = await makeTemplateData()
            let oinfo = await http.post(apis.ORDER_PRE_CREATE,{
                goods_cat: goodsInfo.cat_id,
                goods_hash: goodsInfo.hash_id,
                goods_info: JSON.stringify(goodsInfo),
                cover: ret.cover,
                tpl_id: ret.tpl_id,
                material: JSON.stringify({imgs: [ret.material]}),
                name: goodsInfo.cat_id === 1 ? "DIY手机壳":"拍立得",
                poster
            })
            preOrderNo = oinfo.order_no
        } catch (error) {
            console.log(error)
        }
        Toast.clear()
        setTimeout(()=>{
            if(goodsInfo.cat_id === 1){ //手机壳直接跳转订单确认
                window.wx.miniProgram.navigateTo({ url: `/pages/order_confirm/order_confirm?pre_order_no=${preOrderNo}`})
            }else{
                window.wx.miniProgram.navigateTo({ url: `/pages/pailde_confirm/pailde_confirm?pre_order_no=${preOrderNo}`})
            }
        },300)
    }

    const onOpacityPreview = (v) => {
        canvas.current.setOpacity(curActivityObject,v/100)
    }

    const doShare = async () => {
        Toast.show({
            content: "加载中",
            icon: 'loading',
            duration: 0
        });
        let cover = canvas.current.getFabricCanvas().toDataURL()
        await posterRef.current.make(cover, true)
        Toast.clear()
    }

    return (
        <div className={styles.layout} style={{background: "#F2F2F7"}}>
            <Poster action={posterRef} />
            <div style={{
                position: "relative",
                height: "100vh"
            }}>
                <div style={{
                    opacity: canvasEmpty ? 0.5:1, 
                    pointerEvents: canvasEmpty ? "none":"auto", 
                    display: menuOpen ? "none":"block",
                    position: "absolute",
                    zIndex: 9
                }}>
                    <img onClick={saveToTemplate} src={SaveIcon} style={{
                        position: "fixed",
                        width: 78,
                        height: 78,
                        left: 0,
                        bottom: "calc(60px + env(safe-area-inset-bottom))",
                        zIndex: 11
                    }} alt='' />
                    {/* <img onClick={saveToTemplate} src={SaveIcon} style={{
                        position: "fixed",
                        width: 78,
                        height: 78,
                        left: 135,
                        bottom: "calc(60px + env(safe-area-inset-bottom))",
                        zIndex: 10
                    }} alt='' /> */}
                    <img src={ShareIcon} onClick={doShare} style={{
                        position: "fixed",
                        width: 78,
                        height: 78,
                        left: 66,
                        bottom: "calc(60px + env(safe-area-inset-bottom))",
                        zIndex: 11
                    }} alt='' />
                    <img onClick={doOrder} src={OrderIcon} style={{
                        position: "fixed",
                        width: 78,
                        height: 78,
                        right: 0,
                        bottom: "calc(60px + env(safe-area-inset-bottom))",
                        zIndex: 11
                    }} alt='' />
                </div>
                <div style={{
                    width: "100%",
                    height: "100%",
                    position: "relative"
                }}>
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: "calc(13vh + env(safe-area-inset-bottom))",
                        margin: "auto",
                        width: config.canvasWidth,
                        height: config.canvasHeight
                    }}>
                        <div style={{position: "relative",width: "100%", height: "100%"}}>
                            <div style={{
                                width: 170,
                                height: 105,
                                position: "absolute",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                                display: canvasEmpty ? "flex":"none",
                                zIndex: 10,
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                                <div onClick={() => photoAction.current.openFile()}>
                                    <img style={{width: 82, height: 82}} src='https://file.letodiy.top/%E7%BB%84%20379%402x.png' alt='' />
                                </div>
                                <div style={{height: 23}}></div>
                                <div onClick={() => {
                                    window.wx.miniProgram.switchTab({ url: `/pages/ai1/ai1`})
                                }}>
                                    <img style={{width: 82, height: 82}} src='https://file.letodiy.top/%E7%BB%84%20375%402x.png' alt='' />
                                </div>
                            </div>
                            <canvas id='canvas'></canvas>
                            {
                                controPosition && <>
                                    <img src={patternClose} onClick={delObject} style={{ height: 19, width: 19, zIndex: 9, position: 'absolute', ...controPosition?.tl_position }} alt="" />
                                    <img src={patternScale} style={{ pointerEvents: "none", height: 19, width: 19, zIndex: 9, position: 'absolute', ...controPosition?.br_position }} alt="" />
                                    <img src={patternRote} style={{ pointerEvents: "none", height: 19, width: 19, zIndex: 9, position: 'absolute', ...controPosition?.tr_position }} alt="" />
                                    {
                                        curActivityObject?.isType("text") && <img onClick={editTxt} src={textEdit} style={{ height: 19, width: 35, zIndex: 9, position: 'absolute', ...controPosition?.bl_position }} alt="" />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Bottom 
                onMenu={onMenu}
                curActivityObject={curActivityObject}
            />
            <Pattern 
                action={patternAction}
                onPreview={onPatternPreview}
                onCancel={onPatternCancel}
                onConfirm={onPatternConfirm}
            />
            <Photo
                action={photoAction}
                onConfirm={onPhotoConfirm}
            />
            <Lvjing 
                action={lvjingAction}
                onPreview={onLvjingPreview}
                onCancel={onLvjingCancel}
                onConfirm={onLvjingConfirm}
            />
            <BgColor
                action={bgAction}
                onPreview={onBgColorPreview}
                onCancel={onBgColorCancel}
                onConfirm={onBgColorConfirm}
            />
            <Opacity
                action={opacityAction}
                onPreview={onOpacityPreview}
                onCancel={onOpacityCancel}
                onConfirm={onOpacityConfirm}
            />
            <Slide
                action={slideAction}
                onPreview={onSlidePreview}
                onCancel={onSlideCancel}
                onConfirm={onSlideConfirm}
            />
            <Txt 
                action={txtAction}
                onAddText = {onTextAddText}
                value={txtValue}
                onPreview={onTxtPreview}
                onCancel={onTxtCancel}
                onConfirm={onTxtConfirm}
                onPaddingPreview={v => {
                    if(curActivityObject){
                        canvas.current.setTxtFontPadding(curActivityObject,v)
                    }
                }}
            />
            <Koutu
                action={koutuAction}
                onCancel={onKoutuCancel}
                onConfirm={onKoutuConfirm}
                onPreview={onKoutuPreview}
            />
        </div>
    )
}

export default Index