import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './index.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import CancelIcon from '../../static/img/12.png'
import ConfirmIcon from '../../static/img/11.png'
import ResetIcon from '../../static/img/18.png'
import FreeColorIcon from '../../static/img/19.png'
import axios from 'axios';
import { Toast } from '@nutui/nutui-react';
import D20 from '../../static/img/20.png'
import D21 from '../../static/img/21.png'
import D22 from '../../static/img/22.png'
import D23 from '../../static/img/23.png'
import D24 from '../../static/img/24.png'
import D25 from '../../static/img/25.png'
import { SketchPicker } from 'react-color';
import { Range, ConfigProvider } from '@nutui/nutui-react';
import { cloneDeep } from 'lodash';
import { hexToRgb } from '../../lib/tool'
import loadFont from '../../lib/font'
 

const Index = (props) => {
    const {
        value,
        onCancel,
        onConfirm, 
        onAddText,
        onPreview
    } = props
    const [boxShowStep, setBoxShowStep] = useState(0)
    const [inputValue, setInputValue] = useState('')
    const [editType, setEditType] = useState('font')
    const [attr, setAttr] = useState('')
    const [free, setFree] = useState(false)
    const [fontStyles, setFontStyles] = useState([])
    const [fontBgFree, setFontBgFree] = useState(false)
    const [fontList, setFontList] = useState([])
    const ds = useRef({20: D20, 21:D21, 22: D22, 23: D23, 24: D24, 25: D25})
    const swiperClient = useRef()
    const data = useRef(["#FFFFFF", "#000000", "#4D4D4D", "#808080", "#B3B3B3", "#E6E6E6", "#3b2722", "#5a3f34", "#9f877d", "#d4cbc4", "#556c78", "#90a0ad", "#ced6da", "#f7e6b0", "#f7f4c3", "#f1f5e6", "#e3f0fb", "#eae6f3", "#f4dae2", "#fad3cb", "#205d62", "#3a9284", "#8cba53", "#4d6d45", "#b7d332", "#a5cea3", "#84c4ba", "#a3bbe1", "#9ca1d0", "#4b9ed8", "#1d5192", "#1e296e", "#3f4fa0", "#432082", "#584096", "#853b92", "#831e4c", "#e60012", "#e24c3d", "#f188ac", "#f69796", "#f38435", "#e4ad35", "#edd481", "#f6f19d", "#f0cb59", "#be8a2f", "#2c2929"])
    const [freeStroke, setFreeStroke] = useState(false)

    useImperativeHandle(props.action, () => ({
        show: async (s) => {
            if(s){
                let ret = await axios.get("https://leto-file.oss-cn-beijing.aliyuncs.com/font/font.json?"+new Date().getTime())
                setFontList(ret.data)

                let f = await axios.get("https://leto-file.oss-cn-beijing.aliyuncs.com/font/font_style.json?"+new Date().getTime())
                console.log(f.data)
                setFontStyles(f.data)

            }
            setBoxShowStep(s)
        },
        setInputValue
    }));

    useEffect(()=>{
        if(boxShowStep === 0){
            setInputValue('')
            setEditType('font')
            setFree(false)
        }
    },[boxShowStep])

    useEffect(()=>{
        setFree(false)
        setFontBgFree(false)
    },[attr])

    useEffect(()=>{
        if(editType === "style"){
            setAttr("color")
        }
    },[editType])


    const choice = (v) => {
        // setCur(v)
        let cpValue = cloneDeep(value)
        cpValue.color = v
        onPreview(cpValue)
    }

    const choiceStroke = (v) => {
        // setCur(v)
        let cpValue = cloneDeep(value)
        cpValue.stroke = v
        onPreview(cpValue)
    }

    const inputChange = (e) => {
        setInputValue(e.target.value)
    }

    const freeColor = () => {
        setFree(true)
    }

    const freeColorStroke = () => {
        setFreeStroke(true)
    }

    const onFreeChange = (v) => {
        let cpValue = cloneDeep(value)
        cpValue.color = v.hex
        onPreview(cpValue)
    }

    const onFreeChangeStroke = (v) => {
        let cpValue = cloneDeep(value)
        cpValue.stroke = v.hex
        onPreview(cpValue)
    }

    const onSizeChange = (v) => {
        let cpValue = cloneDeep(value)
        cpValue.fontSize = v
        onPreview(cpValue)
    }

    const onStrokeSizeChange = (v) => {
        let cpValue = cloneDeep(value)
        cpValue.strokeSize = v
        onPreview(cpValue)
    }

    return (
        <>
            <div className={styles.bottomBox} style={{
                display: boxShowStep === 1 ? "block":"none",
                height: 100
            }}>
                <div className={styles.patternBox}>
                    <div style={{height: 8}}></div>
                    <div style={{
                        display: "flex"
                    }}>
                        <div onClick={()=>{
                            setBoxShowStep(0)
                        }} style={{
                            width: 56,
                            flexShrink: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                        </div>
                        <div style={{
                            width: "calc(100vw - 112px)",
                            flexShrink: 0,
                            background: "#F3F3F3",
                            borderRadius: "4px 4px 4px 4px",
                            height: 80,
                            overflow: "hidden",
                            position: "relative"
                        }}>
                            <textarea type='text' value={inputValue} onChange={inputChange} placeholder='输入文字' style={{
                                width: "100%",
                                height: "100%",
                                fontSize: 14,
                                paddingLeft: "0.5em",
                                top: -2,
                                display: "block",
                                position: "absolute"
                            }} />
                        </div>
                        <div onClick={()=>{
                            if(!inputValue){
                                return
                            }
                            onAddText(inputValue)
                            setBoxShowStep(2)
                        }} style={{
                            width: 56,
                            flexShrink: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomBox} style={{
                display: boxShowStep === 2 ? "block":"none",
                height: "auto"
            }}>
                <div className={styles.patternBox}> 
                    <div className={styles.bottomTitle} style={{borderBottom: "solid 1px #EFF0F1"}}>
                        <div style={{marginLeft: 12}} onClick={() => {
                            setBoxShowStep(0)
                            onCancel()
                        }}>
                            <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                        </div>
                        <div style={{width: "calc(100% - 96px)", textAlign: "center", fontSize: 13, color:"#222222",fontWeight: 400}}>
                            <div style={{margin: "0 auto",fontSize: 13,color: "#94969B",fontWeight: 400}}>
                                <span onClick={() => setEditType('font')} style={{padding: "0 35px", color: editType === "font" && "#141414"}}>字体</span>
                                <span onClick={() => setEditType('style')} style={{padding: "0 35px", color: editType === "style" && "#141414"}}>样式</span>
                            </div>
                        </div>
                        <div style={{marginRight: 12}} onClick={() => {
                            setBoxShowStep(0)
                            onConfirm()
                        }}>
                            <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                        </div>
                    </div>
                    <div className={styles.patternBox} style={{height: 200, display: editType === 'font' ? 'block':'none',overflow: "auto"}}>
              
                        <div>
                            <div style={{
                                display: "flex",
                                flexFlow: "wrap",
                                justifyContent: "space-around"
                            }}>
                                {
                                    fontList.map((v, k) => (
                                        <div onClick={async () => {
                                            Toast.show({
                                                content: "加载中",
                                                icon: 'loading',
                                                duration: 0
                                            });
                                            let fname = `font_${v.id}`
                                            await loadFont(fname, v.font)
                                            let cpValue = cloneDeep(value)
                                            cpValue.fontFamily = fname
                                            cpValue.fontFamilyUrl = v.font
                                            onPreview(cpValue)
                                            Toast.clear()
                                        }} style={{
                                            width: 'calc(21vw - 20px)',
                                            height: 'calc(21vw - 20px)',
                                            border: value.fontFamily === `font_${v.id}` ? "solid 2px rgb(192,253,82)":"solid 2px #FFF",
                                            backgroundImage: `url(${v.img})`,
                                            backgroundPosition: "center center",
                                            backgroundSize: "110%",
                                            backgroundRepeat: "no-repeat"
                                        }} key={k}>
                                            {/* <div>
                                                <img src={value.fontFamily === `font_${v.id}` ? v.aimg:v.img} alt='' />
                                            </div> */}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{display: editType === 'style' ? 'block':'none'}}>
                        <div className={styles.patternBox}>
                            <div style={{
                                height: 45,
                                borderBottom: "1px solid #D6D8DB",
                                display: "flex",
                                padding: "0 12px",
                                alignItems: "center"
                            }}>
                                <div onClick={() => {
                                    onPreview({
                                        color: '#000000',
                                        // fontFamily: 'Times New Roman',
                                        fontSize: 60,
                                        strokeSize: 0,
                                        stroke: "#000",
                                        shadow: {
                                            a: 0,
                                            blur: 0,
                                            angle: 0,
                                            offsetX: 0,
                                            offsetY: 0
                                        },
                                        fontBgColor: {r:0,g:0,b:0,a:0},
                                        cuxieAttr: {'1': false,'2': false,'3': false},
                                        alignType: 0,
                                        scale: 1,
                                        charSpacing: 0,
                                        lineHeight:1
                                    }) 
                                }} style={{paddingRight: 20}}>
                                    <img src={ResetIcon} style={{width: 24, height: 24}} alt='' />
                                </div>

                                <Swiper
                                    slidesPerView={"auto"}
                                >
                                    {
                                        fontStyles.map((v, k) => (
                                            <SwiperSlide onClick={() => {
                                                onPreview(v.attr)
                                            }} style={{
                                                width: 42,
                                                height: 42,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }} key={k}>
                                                <img style={{width: 36, height: 36}} src={v.icon} alt='' />
                                            </SwiperSlide>
                                        ))
                                    }

                                </Swiper>

                                {/* <div>
                                    {
                                        fontStyles.map(v => (
                                            <div key={v.icon} style={{width: 36, height: 36}} onClick={() => {
                                                onPreview(v.attr)
                                            }}>
                                                <img style={{width: '100%'}} src={v.icon} alt='' />
                                            </div>
                                        ))
                                    }
                                </div> */}
                            </div>
                            <div style={{
                                display: "flex",
                                fontSize: 13,
                                color: "#94969B",
                                justifyContent: "space-around",
                                padding: "9px 0"
                            }}>
                                <div onClick={() => setAttr('color')} style={{color: attr === 'color' && '#141414'}}>颜色</div>
                                <div onClick={() => setAttr('size')} style={{color: attr === 'size' && '#141414'}}>大小</div>
                                <div onClick={() => setAttr('strokeSize')} style={{color: attr === 'strokeSize' && '#141414'}}>描边</div>
                                <div onClick={() => setAttr('shadow')} style={{color: attr === 'shadow' && '#141414'}}>阴影</div>
                                <div onClick={() => setAttr('font_bg')} style={{color: attr === 'font_bg' && '#141414'}}>背景</div>
                                <div onClick={() => setAttr('cuxie')} style={{color: attr === 'cuxie' && '#141414'}}>粗斜</div>
                                <div onClick={() => setAttr('pailie')} style={{color: attr === 'pailie' && '#141414'}}>排列</div>
                            </div>
                        </div>
                        {
                            attr === 'color' && <div>
                                <div className={styles.patternBox}>
                                    {
                                        free && <div>
                                            <SketchPicker disableAlpha color={value.color} onChange={onFreeChange} />
                                        </div>
                                    }
                                    {
                                        !free && <div style={{overflow: "hidden", width: "100%", display: "flex"}}>
                                            <div style={{height: 17}}></div>
                                            <div onClick={freeColor} style={{background: "red",flexShrink: 0,marginLeft: 10,marginRight: 10,border: "none"}} className={styles.bgColorItem}>
                                                <img src={FreeColorIcon} style={{width: 24, height: 24}} alt='' />
                                            </div>
                                            <Swiper
                                                onSwiper={s => swiperClient.current = s}
                                                slidesPerView={"auto"}
                                            >
                                                {
                                                    data.current.map((v, k) => (
                                                        <SwiperSlide onClick={() => choice(v)} style={{
                                                            width: 'auto',
                                                            paddingRight: k === data.current.length - 1 ? 0:7
                                                        }} key={k}>
                                                            <div style={{background: v}} className={styles.bgColorItem}></div>
                                                        </SwiperSlide>
                                                    ))
                                                }
                                                <SwiperSlide style={{
                                                    width: 'auto',
                                                    paddingRight: 10
                                                }}></SwiperSlide>
                                            </Swiper>
                                        </div>
                                    }
                                    <div style={{height: 17}}></div>
                                </div>
                            </div>
                        }
                        {
                            attr === "size" && <div style={{padding: "0 12px"}}>
                                <div style={{height: 32}}></div>
                                <ConfigProvider
                                    theme={{
                                        nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                        nutuiRangeActiveColor:'#AAFF00',
                                        nutuiRangeInactiveColor: '#DCDCDC',
                                        nutuiRangeMargin: '10px',
                                        nutuiRangeHeight: '4px',
                                        nutuiRangeButtonWidth: '16px',
                                        nutuiRangeButtonHeight: '16px'
                                    }}
                                    >
                                    <Range
                                        min={12}
                                        max={150}
                                        onChange={onSizeChange}
                                        value={value.fontSize}
                                    />
                                </ConfigProvider>
                                <div style={{height: 32}}></div>
                            </div>
                        }
                        {
                            attr === "strokeSize" && <div style={{padding: "0 12px"}}>
                                {
                                    freeStroke && <div>
                                        <SketchPicker disableAlpha color={value.colorStroke} onChange={onFreeChangeStroke} />
                                    </div>
                                }
                                {
                                    !freeStroke && <div style={{overflow: "hidden", width: "100%", display: "flex"}}>
                                        <div style={{height: 17}}></div>
                                        <div onClick={freeColorStroke} style={{background: "red",flexShrink: 0,marginLeft: 10,marginRight: 10,border: "none"}} className={styles.bgColorItem}>
                                            <img src={FreeColorIcon} style={{width: 24, height: 24}} alt='' />
                                        </div>
                                        <Swiper
                                            onSwiper={s => swiperClient.current = s}
                                            slidesPerView={"auto"}
                                        >
                                            {
                                                data.current.map((v, k) => (
                                                    <SwiperSlide onClick={() => choiceStroke(v)} style={{
                                                        width: 'auto',
                                                        paddingRight: k === data.current.length - 1 ? 0:7
                                                    }} key={k}>
                                                        <div style={{background: v}} className={styles.bgColorItem}></div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                            <SwiperSlide style={{
                                                width: 'auto',
                                                paddingRight: 10
                                            }}></SwiperSlide>
                                        </Swiper>
                                    </div>
                                }
                                <div style={{height: 20}}></div>
                                <ConfigProvider
                                    theme={{
                                        nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                        nutuiRangeActiveColor:'#AAFF00',
                                        nutuiRangeInactiveColor: '#DCDCDC',
                                        nutuiRangeMargin: '10px',
                                        nutuiRangeHeight: '4px',
                                        nutuiRangeButtonWidth: '16px',
                                        nutuiRangeButtonHeight: '16px'
                                    }}
                                    >
                                    <Range
                                        min={0}
                                        step={0.001}
                                        max={10}
                                        onChange={onStrokeSizeChange}
                                        value={value.strokeSize}
                                    />
                                </ConfigProvider>
                                <div style={{height: 32}}></div>
                            </div>
                        }
                        {
                            attr === "shadow" && <div style={{padding: "0 12px"}}>
                                <div style={{height: 32}}></div>
                                <div style={{display: "flex", alignItems: "center",marginBottom: 20}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>透明</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={0}
                                                step={0.01}
                                                max={1}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.shadow.a = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.shadow.a}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div>
                                <div style={{display: "flex", alignItems: "center",marginBottom: 20}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>模糊</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={0}
                                                max={10}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.shadow.blur = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.shadow.blur}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div>
                                <div style={{display: "flex", alignItems: "center",marginBottom: 20}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>角度</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={0}
                                                max={100}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.shadow.offsetX = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.shadow.offsetX}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div>
                                <div style={{display: "flex", alignItems: "center",marginBottom: 20}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>距离</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={0}
                                                max={100}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.shadow.offsetY = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.shadow.offsetY}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div>
                                <div style={{height: 32}}></div>
                            </div>
                        }
                        {
                            attr === "font_bg" && <div style={{padding: "0 12px"}}>
                                {
                                    fontBgFree && <div>
                                        <SketchPicker disableAlpha color={`rgba(${value.fontBgColor.r},${value.fontBgColor.g},${value.fontBgColor.b},${value.fontBgColor.a})`} onChange={(v)=>{
                                            let cpValue = cloneDeep(value)
                                            cpValue.fontBgColor.r = v.rgb.r
                                            cpValue.fontBgColor.g = v.rgb.g
                                            cpValue.fontBgColor.b = v.rgb.b
                                            onPreview(cpValue)
                                        }} />
                                    </div>
                                }
                                {
                                    !fontBgFree && <div style={{overflow: "hidden", width: "100%", display: "flex"}}>
                                        <div style={{height: 17}}></div>
                                        <div style={{
                                            flexShrink: 0,
                                            marginRight: 10,
                                            border: "none",
                                            background: "none",
                                            width: 55,
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }} className={styles.bgColorItem}>
                                            <img src={ResetIcon} onClick={() => {
                                                let cpValue = cloneDeep(value)
                                                cpValue.fontBgColor = {r:0,g:0,b:0,a:0}
                                                onPreview(cpValue)
                                            }} style={{width: 24, height: 24}} alt='' />
                                            <img src={FreeColorIcon} onClick={() => setFontBgFree(true)} style={{width: 24, height: 24}} alt='' />
                                        </div>
                                        <Swiper
                                            onSwiper={s => swiperClient.current = s}
                                            slidesPerView={"auto"}
                                        >
                                            {
                                                data.current.map((v, k) => (
                                                    <SwiperSlide onClick={() => {
                                                        let rgb = hexToRgb(v)
                                                        let cpValue = cloneDeep(value)
                                                        cpValue.fontBgColor.r = rgb.r
                                                        cpValue.fontBgColor.g = rgb.g
                                                        cpValue.fontBgColor.b = rgb.b
                                                        onPreview(cpValue)
                                                    }} style={{
                                                        width: 'auto',
                                                        paddingRight: k === data.current.length - 1 ? 0:7
                                                    }} key={k}>
                                                        <div style={{background: v}} className={styles.bgColorItem}></div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                            <SwiperSlide style={{
                                                width: 'auto',
                                                paddingRight: 10
                                            }}></SwiperSlide>
                                        </Swiper>
                                    </div>
                                }
                                <div style={{height: 26}}></div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>透明</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={0}
                                                step={0.01}
                                                max={1}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.fontBgColor.a = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.fontBgColor.a}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div>
                                <div style={{height: 32}}></div>
                            </div>
                        }
                        {
                            attr === "cuxie" && <div style={{padding: "0 12px"}}>
                                <div style={{height: 32}}></div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-evenly"
                                }}>
                                    <div style={{borderColor: value.cuxieAttr['1'] && 'rgba(170, 255, 0, 1)'}} className={styles.cuxieItem} onClick={() => {
                                        let cpValue = cloneDeep(value)
                                        cpValue.cuxieAttr['1'] = !cpValue.cuxieAttr['1']
                                        onPreview(cpValue)
                                    }}><i>I</i></div>
                                    <div style={{borderColor: value.cuxieAttr['2'] && 'rgba(170, 255, 0, 1)'}} className={styles.cuxieItem} onClick={() => {
                                        let cpValue = cloneDeep(value)
                                        cpValue.cuxieAttr['2'] = !cpValue.cuxieAttr['2']
                                        onPreview(cpValue)
                                    }}><b>B</b></div>
                                    {/* <div style={{borderColor: value.cuxieAttr['3'] && 'rgba(170, 255, 0, 1)'}} className={styles.cuxieItem} onClick={() => {
                                        let cpValue = cloneDeep(value)
                                        cpValue.cuxieAttr['3'] = !cpValue.cuxieAttr['3']
                                        onPreview(cpValue)
                                    }}><u>U</u></div> */}
                                </div>
                                <div style={{height: 32}}></div>
                            </div>
                        }
                        {
                            attr === "pailie" && <div style={{padding: "0 12px"}}>
                                <div style={{height: 20}}></div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-evenly"
                                }}>
                                    {
                                        [20,21,22].map(v => (
                                            <div key={v} onClick={()=>{
                                                let cpValue = cloneDeep(value)
                                                cpValue.alignType = v
                                                onPreview(cpValue)
                                            }} className={styles.pailieDItem}>
                                                <img src={ds.current[v]} alt='' />
                                            </div>
                                        ))
                                    }
                                </div>
                                {/* <div style={{height: 26}}></div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>缩放</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={1}
                                                max={10}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.scale = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.scale}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div> */}
                                <div style={{height: 26}}></div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>字距</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={0}
                                                max={1000}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.charSpacing = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.charSpacing}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div>
                                <div style={{height: 26}}></div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div style={{fontSize: 14, color: "rgba(0,0,0,0.4)",paddingRight: 10}}>行距</div>
                                    <ConfigProvider
                                        theme={{
                                            nutuiRangeButtonBorder: '1px solid #DCDCDC',
                                            nutuiRangeActiveColor:'#AAFF00',
                                            nutuiRangeInactiveColor: '#DCDCDC',
                                            nutuiRangeMargin: '10px',
                                            nutuiRangeHeight: '4px',
                                            nutuiRangeButtonWidth: '16px',
                                            nutuiRangeButtonHeight: '16px'
                                        }}
                                        >
                                        <div style={{width: "calc(100vw - 70px)"}}>
                                            <Range
                                                min={1}
                                                max={10}
                                                onChange={v => {
                                                    let cpValue = cloneDeep(value)
                                                    cpValue.lineHeight = v
                                                    onPreview(cpValue)
                                                }}
                                                value={value.lineHeight}
                                            />
                                        </div>
                                    </ConfigProvider>
                                </div>
                                

                                <div style={{height: 20}}></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index