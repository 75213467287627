import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './index.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import CancelIcon from '../../static/img/12.png'
import ConfirmIcon from '../../static/img/11.png'
 

const Index = (props) => {
    const {onPreview,onCancel,onConfirm} = props
    const [boxShow, setBoxShow] = useState(false)
    const [cur, setCur] = useState({})
    const data = useRef([
        {
            "id":1,
            "name":"原始",
            "colors": [0,0,0],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":2,
            "name":"灰度",
            "colors": [100,0,0],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":3,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":4,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":5,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":6,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":7,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":8,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":9,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        },
        {
            "id":10,
            "name":"原始",
            "colors": [100,100,100],
            "url":"https://file.xihugongchang.cn/3.png"
        }
    ])
    const swiperClient = useRef()

    useImperativeHandle(props.action, () => ({
        show: async (s) => {
            setBoxShow(s)
        }
    }));

    useEffect(()=>{
        if(!boxShow){
            setCur({})
        }
    },[boxShow])


    const choice = (v) => {
        setCur(v)
        onPreview(v)
    }




    return (
        <div className={styles.bottomBox} style={{
            display: boxShow ? "block":"none",
            height: 168
        }}>
            <div className={styles.bottomTitle} style={{borderBottom: "solid 1px #EFF0F1"}}>
                <div style={{marginLeft: 12}} onClick={() => {
                    setBoxShow(false)
                    onCancel()
                }}>
                    <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                </div>
                <div style={{width: "calc(100% - 96px)", textAlign: "center", fontSize: 13, color:"#222222",fontWeight: 400}}>
                    滤镜
                </div>
                <div style={{marginRight: 12}} onClick={() => {
                    setBoxShow(false)
                    onConfirm()
                }}>
                    <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                </div>
            </div>
            <div className={styles.patternBox}>
                <div style={{height: 20}}></div>
                <div style={{height: 111, overflow: "hidden", width: "100%"}}>
                    <Swiper
                        onSwiper={s => swiperClient.current = s}
                        slidesPerView={"auto"}
                    >
                        <SwiperSlide style={{
                            width: 'auto',
                            paddingRight: 10
                        }}></SwiperSlide>
                        {
                            data.current.map((v, k) => (
                                <SwiperSlide onClick={() => choice(v)} style={{
                                    width: 'auto',
                                    paddingRight: k === data.current.length - 1 ? 0:7
                                }} key={k}>
                                    <div style={{width: 51, height: 94, position: "relative",border: cur.id === v.id ? "3px solid #AAFF00":"3px solid rgba(0,0,0,0)"}}>
                                        <img src={v.url} style={{display: "block", width: "100%", height: "100%"}} alt='' />
                                        <div style={{
                                            height: 16,
                                            background:"#222222",
                                            color:"#fff",
                                            textAlign: "center",
                                            fontSize: 12, 
                                            lineHeight:"16px",
                                            position: "absolute",
                                            left: 0,
                                            bottom: 0,
                                            width: "100%"
                                        }}>{v.name}</div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                        <SwiperSlide style={{
                            width: 'auto',
                            paddingRight: 10
                        }}></SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Index