import { useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.css'
import CancelIcon from '../../static/img/12.png'
import ConfirmIcon from '../../static/img/11.png'

const Index = (props) => {
    const {onPreview,onCancel,onConfirm} = props
    const [boxShow, setBoxShow] = useState(false)
    const [btn, setBtn] = useState('')

    useImperativeHandle(props.action, () => ({
        show: async (s) => {
            setBoxShow(s)
        }
    }));

    useEffect(()=>{
        if(!boxShow){
            setBtn('')
        }
    },[boxShow])

    const choiceBtn = async (b) => {
        await onPreview(b)
        setBtn(b)
    }


    return (
        <div className={styles.bottomBox} style={{
            display: boxShow ? "block":"none",
            height: "auto"
        }}>
            <div className={styles.bottomTitle}>
                <div style={{marginLeft: 12}} onClick={() => {
                    setBoxShow(false)
                    onCancel()
                }}>
                    <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                </div>
                <div style={{width: "calc(100% - 96px)", display: "flex",fontSize: 14,alignItems:"center",justifyContent:"center"}}>
                    抠图
                </div>
                <div style={{marginRight: 12}} onClick={() => {
                    setBoxShow(false)
                    onConfirm()
                }}>
                    <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                </div>
            </div>
            <div className={styles.patternBox}>
                <div style={{height: 86, overflow: "auto", marginLeft: "2vw"}}>
                    <div style={{height: 20}}></div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <div onClick={() => choiceBtn('1')} className={styles.koutuBtn} style={{marginRight: 50, background: btn === '1' && '#AAFF00'}}>抠图</div>
                        <div onClick={() => choiceBtn('2')} className={styles.koutuBtn} style={{background: btn === '2' && '#AAFF00'}}>头像</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index