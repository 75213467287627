import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../pages/home'
import { useEffect } from "react";



const R = () => {
    

    useEffect(()=>{
        
    },[])
    
    return (
        <BrowserRouter>
            <Routes> 
                <Route path="/:id/:token" element={<Home />} />                   
            </Routes>
        </BrowserRouter>
    )
}

export default R
