

export const handleLevelData = (data) => {
    const one = []
    for(let i=0;i<data.length;i++){
        let item = data[i]
        item.label = item.name
        item.value = item.id
        item.key = item.id.toString()
        if(item.super_id === 0){
            one.push(item)
        }
    }
    return a(one,data)
}

const a = (one,data) => {
    for(let i=0;i<data.length;i++){
        let item = data[i]
        let f = one.findIndex(v => v.id === item.super_id)
        if(f !== -1){
            if(!one[f].children){
                one[f].children = [item]
            }else{
                one[f].children.push(item)
            }
            a(one[f].children, data)
        }
    }
    return one
}


export const fullscreen = () => {
    let ele = document.body
    if (ele.requestFullscreen) {
        ele.requestFullscreen();
    } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
    } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
    } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
    }
}

export const isFullScreen = () => {
    return  !! (
        document.fullscreen || 
        document.mozFullScreen ||                         
        document.webkitIsFullScreen ||       
        document.webkitFullScreen || 
        document.msFullScreen 
     );
}

export const exitFullscreen = () => {
    if(document.exitFullScreen) {
        document.exitFullScreen();
    } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if(document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}

export function sleep(time){
    return new Promise((resolve, reject)=>{
        setTimeout(()=>{
            resolve()
        },time)
    })
}

export const processor = {  
    timeoutId: null,
    performProcessing: function() {
        var days = new Date()
        var a= days.getHours();
        var b= days.getMonth();
        var c= days.getSeconds();
        var d= days.getMilliseconds();
        console.log(a+'时'+b+'分'+c+'秒'+d+'毫秒');
    },
  	process: function(cb) {
  	  var that = this;
      clearTimeout(that.timeoutId);    
      that.timeoutId = setTimeout(function() {
        cb();
      }, 500)                     //每隔500毫秒后执行，如果中间间隔没到五秒钟还在输入，那么这500毫秒将重新开始计算
  	}
}



export const saveAs = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
        //IE保存Blob对象
        navigator.msSaveBlob(blob, filename)
    } else {
        //其他浏览器
        var link = document.createElement('a')
        var body = document.querySelector('body')
        /*window.URL.createObjectURL可以用于在浏览器上预览本地图片或者视频,创建一个url*/
        link.href = window.URL.createObjectURL(blob)//为Blob对象创建一个url地址
        link.download = filename
/*
如果不设置download属性的话，呢么如果是jpg、txt等浏览器支持打开的属性，就会在浏览器打开该文件而不是下载下来
*/

        // fix Firefox
        link.style.display = 'none'
        body.appendChild(link)

        link.click()
        body.removeChild(link)

        window.URL.revokeObjectURL(link.href)// 释放地址
    }
}

export const copyToClip = (content) => {
    var aux = document.createElement("input"); 
    aux.setAttribute("value", content); 
    document.body.appendChild(aux); 
    aux.select();
    document.execCommand("copy"); 
    document.body.removeChild(aux);
}

export const filterCodes = (items) => {
    const codes = []
    for(let i=0;i<items?.length;i++){
        if(items[i].renderFabric){
            codes.push(items[i].code)
        }
    }
    return codes
}

export function hexToRgb(hex) {
    // 去除可能包含的#字符
    hex = hex.replace(/^#/, '');
  
    // 将16进制颜色代码转换为RGB值
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;
  
    // 返回RGB值对象
    return { r: r, g: g, b: b };
}

export function rgbaStringToObject(rgbaString) {
    // 使用正则表达式匹配字符串中的数字部分
    const rgbaValues = rgbaString.match(/\d+(\.\d+)?/g);
  
    if (!rgbaValues || rgbaValues.length !== 4) {
      return {
        r: 0,
        g: 0,
        b: 0,
        a: 0
      }
    }
  
    // 将提取的值分配给对象属性
    const rgbaObject = {
      r: parseFloat(rgbaValues[0]),
      g: parseFloat(rgbaValues[1]),
      b: parseFloat(rgbaValues[2]),
      a: parseFloat(rgbaValues[3]),
    };
  
    return rgbaObject;
  }