import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './index.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import CancelIcon from '../../static/img/12.png'
import ConfirmIcon from '../../static/img/11.png'
import { SketchPicker } from 'react-color';
import FreeColorIcon from '../../static/img/19.png'
 

const Index = (props) => {
    const {onPreview,onCancel,onConfirm} = props
    const [boxShow, setBoxShow] = useState(false)
    // const [cur, setCur] = useState({})
    const [free, setFree] = useState(false)
    const [freeColorValue, setFreeColor] = useState('')
    const data = useRef(["rgba(0,0,0,0)","#FFFFFF", "#000000", "#4D4D4D", "#808080", "#B3B3B3", "#E6E6E6", "#3b2722", "#5a3f34", "#9f877d", "#d4cbc4", "#556c78", "#90a0ad", "#ced6da", "#f7e6b0", "#f7f4c3", "#f1f5e6", "#e3f0fb", "#eae6f3", "#f4dae2", "#fad3cb", "#205d62", "#3a9284", "#8cba53", "#4d6d45", "#b7d332", "#a5cea3", "#84c4ba", "#a3bbe1", "#9ca1d0", "#4b9ed8", "#1d5192", "#1e296e", "#3f4fa0", "#432082", "#584096", "#853b92", "#831e4c", "#e60012", "#e24c3d", "#f188ac", "#f69796", "#f38435", "#e4ad35", "#edd481", "#f6f19d", "#f0cb59", "#be8a2f", "#2c2929"])
    const swiperClient = useRef()

    useImperativeHandle(props.action, () => ({
        show: async (s) => {
            setBoxShow(s)
        }
    }));

    useEffect(()=>{
        if(!boxShow){
            // setCur({})
            setFree(false)
        }
    },[boxShow])


    const choice = (v) => {
        // setCur(v)
        onPreview(v)
    }

    const freeColor = () => {
        setFree(true)
    }

    const onFreeChange = (v) => {
        setFreeColor(v.hex)
        onPreview(v.hex)
    }


    return (
        <>
            <div className={styles.bottomBox} style={{
                display: boxShow && !free ? "block":"none",
                height: 111
            }}>
                <div className={styles.bottomTitle} style={{borderBottom: "solid 1px #EFF0F1"}}>
                    <div style={{marginLeft: 12}} onClick={() => {
                        setBoxShow(false)
                        onCancel()
                    }}>
                        <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                    <div style={{width: "calc(100% - 96px)", textAlign: "center", fontSize: 13, color:"#222222",fontWeight: 400}}>
                        颜色选择
                    </div>
                    <div style={{marginRight: 12}} onClick={() => {
                        setBoxShow(false)
                        onConfirm()
                    }}>
                        <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                </div>
                <div className={styles.patternBox}>
                    <div style={{height: 20}}></div>
                    <div style={{overflow: "hidden", width: "100%", display: "flex"}}>
                        <div onClick={freeColor} style={{background: "red",flexShrink: 0,marginLeft: 10,marginRight: 10,border: "none"}} className={styles.bgColorItem}>
                            <img src={FreeColorIcon} style={{width: 24, height: 24}} alt='' />
                        </div>
                        {/* <div onClick={freeColor} style={{background: "red",flexShrink: 0,marginLeft: 10,marginRight: 10}} className={styles.bgColorItem}></div> */}
                        <Swiper
                            onSwiper={s => swiperClient.current = s}
                            slidesPerView={"auto"}
                        >
                            {
                                data.current.map((v, k) => (
                                    <SwiperSlide onClick={() => choice(v)} style={{
                                        width: 'auto',
                                        paddingRight: k === data.current.length - 1 ? 0:7
                                    }} key={k}>
                                        <div style={{background: k === 0 ? `url(https://leto-file.oss-cn-beijing.aliyuncs.com/1703638308510.jpg)`: v,backgroundSize: "95%"}} className={styles.bgColorItem}></div>
                                    </SwiperSlide>
                                ))
                            }
                            <SwiperSlide style={{
                                width: 'auto',
                                paddingRight: 10
                            }}></SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className={styles.bottomBox} style={{
                display: boxShow && free ? "block":"none",
                height: 285
            }}>
                <div className={styles.bottomTitle} style={{borderBottom: "solid 1px #EFF0F1"}}>
                    <div style={{marginLeft: 12}} onClick={() => {
                        setBoxShow(false)
                        onCancel()
                    }}>
                        <img src={CancelIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                    <div style={{width: "calc(100% - 96px)", textAlign: "center", fontSize: 13, color:"#222222",fontWeight: 400}}>
                        颜色选择
                    </div>
                    <div style={{marginRight: 12}} onClick={() => {
                        setBoxShow(false)
                        onConfirm()
                    }}>
                        <img src={ConfirmIcon} style={{width: 24, height: 24}} alt='' />
                    </div>
                </div>
                <div className={styles.patternBox}>
                    <div style={{height: 20}}></div>
                    <SketchPicker disableAlpha color={freeColorValue} onChange={onFreeChange} />
                </div>
            </div>
        </>
    )
}

export default Index